import logo from './logo.svg';
import './App.css';
import Navbar from './components/navbar/Navbar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Product from './pages/products/Product';
import Home from './pages/home/Home';
import About from './pages/about/About';

function App() {
  return (
  <BrowserRouter>
    <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/products' element={<Product/>} />
        <Route path='/about' element={<About/>} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
