import React, { useState } from 'react'
import './navbar.scss'
import { useNavigate } from 'react-router-dom'
import { faBars, faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from '../../images/logo.png'
import { click } from '@testing-library/user-event/dist/click'

const Navbar = () => {
  const navigate = useNavigate()
  const [click, setClick] = useState(false)

  const styles = {
      position: 'fixed',
      top: 48,
      right: click ? 0 : '60vw', // Change '-250px' to the width of your sidebar
      width: '60vw', // Adjust width as needed
      background: 'lightgray', // Adjust background as needed
      transition: 'right 0.3s ease-in-out', // Smooth transition
      zIndex: 999999999, // Ensure it appears above other elements
      display: click ? 'block' : 'none', // Control visibility
  }

  const eehYea = () => {
    setClick(!click)
  }

  return (
    <>
    <div className="newchild">
      <div style={styles} className="uppbar">
        <div className="uppbarinside">
          <div onClick={()=>navigate("/")} className="itemsbar">Home</div>
          <div  className="line"></div>
          <div onClick={()=>navigate("/products/")} className="itemsbar">Products</div>
          <div className="line"></div>
          <div onClick={()=>navigate("/about/")}className="itemsbar">About Us</div>
          <div className="line"></div>
          <div onClick={()=>navigate("/contact/")} className="itemsbar">Contact Us</div>
        </div>
      </div>
           <div className="loger">
              <img src={logo} alt="" />
           </div>
           <div className="expander">
            {!click && <div onClick={()=>eehYea()}><FontAwesomeIcon  icon={faBars} /></div>}
            {click && <FontAwesomeIcon onClick={()=>setClick(!click)} icon={faXmark} />}
           </div>
        </div>
    <div className='navParent'>
        
        <div className="navChild">
            <div onClick={()=>navigate("/")} className="logo">
              <img src={logo} alt="" />
            </div>
            <div className="links">
                <div onClick={()=>navigate("/")} className="linkItem">Home</div>
                <div onClick={()=>navigate("/products/")} className="linkItem">Products</div>
                <div onClick={()=>navigate("/about/")} className="linkItem">About Us</div>
                <div className="linkItem">Contact Us</div>
            </div>
            <div className="contact">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
            </div>

        </div>
      
    </div>
    </>
  )
}

export default Navbar
