import React from 'react'
import background from "../../images/woman.avif"
import './picturecard.scss'

const PictureCard = () => {
    const styles = {
        background: `url(${background})`,
        backgroundSize: 'cover', // Optional: Adjust to your needs
        backgroundRepeat: 'no-repeat', // Optional: Adjust to your needs
        backgroundPosition: 'center', // Optional: Adjust to your needs
      };
  return (
    <div className='pictureCard'>
        <div className="picturecardChild">
            <div style={styles} className="picLeft">

            </div>
            <div className="picRight">
                <div className="innerworkings">
                    <div className="header">
                        <div className="headerleft">
                        
                        </div>
                        <div className="headerriht">
                            <div className="upper">
                                Skin Care With Purity
                            </div>
                            <div className="lower">
                                Glazing: All You Need to Know
                            </div>
                        </div>
                    </div>
                    <div className="lowerbody">
                    Enhance your beauty routine and achieve beautiful, healthy skin with just one additional step. Discover the benefits of slugging with Vaseline® Original Healing Jelly!
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PictureCard
