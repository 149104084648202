import React from 'react'
import './card.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'


const HomeCard = ({img,name,description}) => {
  return (
    <div className='homepcarddiv'>
       <div className="imagearea">
            <img src={img} alt="" />
       </div>
       <div className="stars">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
       </div>
       <div className="pname">
            {name}
       </div>
       <div className="pdescription">
            {description}
       </div>
    </div>
  )
}

export default HomeCard
