import React from 'react'
import './cardabout.scss'
import background from '../../images/woman.avif'

const CardAbout = () => {
    const styles = {
        background: `url(${background})`,
        backgroundSize: 'cover', // Optional: Adjust to your needs
        backgroundRepeat: 'no-repeat', // Optional: Adjust to your needs
        backgroundPosition: 'center', // Optional: Adjust to your needs
      };
  return (
    <div className='aboutcard'>
      <div className="aboucardin">
        <div className="aboutleft">
            <div className="inner">
                <div className="aheader">
                    <div className="ahleft">

                    </div>
                    <div className="ahright">
                       <div className="topper">
                            Serving Malawians with Quality
                       </div>
                       <div className="downer">
                            About Purity Sanitary Products Malawi
                       </div>
                    </div>
                </div>
                <div className="downerbody">
                Since the very beginning of our story, the Purity Sanitary brand has been dedicated to 
                helping people in Malawi achieve healthier skin. 
                Our range of products, including lotion, body wash soap, hand wash soap, and sanitary napkins, are the result of years of research and development. Our mission is clear and ongoing: to create accessible, everyday skincare products, provide education and access to skin health care for those in need, and help everyone, 
                everywhere maintain healthy skin to meet the demands of daily life.
                </div>
                <button>Contact Us</button>
            </div>

        </div>
        <div style={styles} className="aboutright">
            
        </div>
      </div>
    </div>
  )
}

export default CardAbout
