import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import './product.scss'
import Card from '../../components/productcard/Card'
import IMG_9354 from '../../images/IMG_9354.png'
import IMG_93744 from '../../images/IMG_9374(1).png'
import IMG_9362 from '../../images/IMG_9362.png'
import IMG_9402 from '../../images/IMG_9402.png'
import Footer from '../../components/footer/Footer'
import Lower from '../../components/footer/Lower'
import IMG_9349 from '../../images/IMG_9349.png'
import IMG_9360 from '../../images/IMG_9360.png'
import IMG_9370 from "../../images/IMG_9370.png"
import IMG_9416 from '../../images/IMG_9416.png'
import IMG_9414 from '../../images/IMG_9414.png'
import IMG_9352 from '../../images/IMG_9352.png'
import IMG_9356 from '../../images/IMG_9356.png'
import IMG_9358 from '../../images/IMG_9358.png'
import IMG_9389 from '../../images/IMG_9389.png'
import IMG_9387 from '../../images/IMG_9408.png'
import IMG_9410 from '../../images/IMG_9380.png'

const Product = () => {
  return (
    <div className='homedivvv'>
      <Navbar/>
      <div className="productLocation">
        <div className="plhild">
              <div className="locationIcon"> <FontAwesomeIcon icon={faHome} />  </div>
              <div className="locationword"> {">"} Products</div>
        </div>
      </div>
      <div className="productsHeading">
        <div className="productsheadingchild">
            Our Products
        </div>
      </div>
      <div className="types">
        <div className="typeschild">
          <div className="typeitem">Lotion</div>
          <div className="typeitem">Handwash</div>
          <div className="typeitem">Dishwash</div>
          <div className="typeitem">Sanitary Napkins</div>
        </div>
      </div>
      <div className="productsconatiner">
        <div className="conatinerinside">
          <Card img={IMG_9354} name={"Body Wash"} description={"Deep Clean"}/>
          <Card img={IMG_9358} name={"Body Wash"} description={"Rose Care"}/>
          <Card img={IMG_9352} name={"Body Wash"} description={"Mint"}/>
          <Card img={IMG_93744} name={"Body Lotion"} description={"Olive"}/>
          <Card img={IMG_9414} name={"Body Lotion"} description={"Aloe Vera"}/>
          <Card img={IMG_9370} name={"Body Lotion"} description={"Papaya"}/>

          <Card img={IMG_9362} name={"Hand Wash"} description={"Deo Fresh"}/>
          <Card img={IMG_9356} name={"Body Wash"} description={"Deep Clean"}/>
          <Card img={IMG_9402} name={"Sanitary Napkins"} description={"Normal Plus"}/>
          <Card img={IMG_9349} name={"Dish Wash"} description={"Lemon Fresh"}/>
          <Card img={IMG_9360} name={"Dish Wash"} description={"Antibacterial"}/>
          <Card img={IMG_9416} name={"Dish Wash - 5L"} description={"Antibacterial"}/>

          <Card img={IMG_9389} name={"Sanitary Napkins"} description={"Ultra Thin"}/>
          <Card img={IMG_9387} name={"Sanitary Napkins"} description={"Ultra Thin"}/>
          <Card img={IMG_9410} name={"Sanitary Napkins"} description={"Maxi Night Fresh"}/>

        </div>
      </div>
      <Footer/>
      <Lower/>
      
    </div>
  )
}

export default Product
