import React from 'react'
import './footer.scss'
import social from '../../images/social.png'

const Footer = () => {
  return (
    <div className='footer'>
        <div className="footerchild">
            <div className="left">
                <div className="leftheading">
                    CONTACT US
                </div>
                <div className="conatclist">
                    +265 99128 1977 | info@puritysanitary.mw
                </div>
                <div className="line"></div>
            </div>
            <div className="right">
            <div className="leftheading">
                    SUBSCRIBE WITH PURITY TODAY!
                </div>
                <div className="conatclist">
                        Discover the latest updates, care advice, inspirations, and exclusive deals.
                </div>
                <div className="inputdata">
                    <input placeholder='Email' type="text" />
                    <button>Subscribe</button>
                </div>
                <div className="line"></div>
                <div className="leftheading">
                    STAY CONNECTED
                </div>
                <div className="imagess">
                    <img src={social} alt="" />
                </div>
            </div>
        </div>
      
    </div>
  )
}

export default Footer
