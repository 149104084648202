import React from 'react'
import CardAbout from '../../components/aboutcard/CardAbout'
import Navbar from '../../components/navbar/Navbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import Footer from '../../components/footer/Footer'
import Lower from '../../components/footer/Lower'

const About = () => {
  return (
    <div>
      <Navbar/>
      <div className="productLocation">
        <div className="plhild">
              <div className="locationIcon"> <FontAwesomeIcon icon={faHome} />  </div>
              <div className="locationword"> {">"} About</div>
        </div>
      </div>
      <CardAbout/>
      <Footer/>
      <Lower/>
    </div>
  )
}

export default About
