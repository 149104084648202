import React from 'react'
import './footer.scss'

const Lower = () => {
  return (
    <div className='lowerFooter'>
       Purity Sanitary Products Malawi @ 2024 | All rights reserved | Developed by Kho Zola
    </div>
  )
}

export default Lower
