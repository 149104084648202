import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import './home.scss'
import background from '../../images/headern.webp'
import IMG_9354 from '../../images/IMG_9354.png'
import IMG_93744 from '../../images/IMG_9374(1).png'
import IMG_9362 from '../../images/IMG_9362.png'
import IMG_9402 from '../../images/IMG_9402.png'
import IMG_9414 from '../../images/IMG_9414.png'
import IMG_9352 from '../../images/IMG_9352.png'
import IMG_9356 from '../../images/IMG_9356.png'
import IMG_9358 from '../../images/IMG_9358.png'
import IMG_9389 from '../../images/IMG_9389.png'
import IMG_9387 from '../../images/IMG_9408.png'
import IMG_9410 from '../../images/IMG_9380.png'
import IMG_9370 from "../../images/IMG_9370.png"

import Carousel from 'react-multi-carousel'
import "react-multi-carousel/lib/styles.css";
import HomeCard from '../../components/productcard/HomeCard'
import lotion from '../../images/lotion.png'
import wash from '../../images/body-wash.png'
import dish from '../../images/dish-soap.png'
import napkin from '../../images/napkin.png'
import hand from '../../images/liquid-soap.png'
import Footer from '../../components/footer/Footer'
import Lower from '../../components/footer/Lower'
import PictureCard from '../../components/picturecard/PictureCard'



const Home = () => {
    const cards = [
        { img: `${IMG_9354}`, name: 'name for card 1' },
        { img: `${IMG_93744}`, name: 'name for card 2' },
        { img: `${IMG_9362}`, name: 'name for card 3' },
        { img: `${IMG_9402}`, name: 'name for card 4' },
        { img: 'Card 5', name: 'name for card 5' },
        { img: 'Card 6', name: 'name for card 6' },
      ];
    const styles = {
        background: `url(${background})`,
        backgroundSize: 'cover', // Optional: Adjust to your needs
        backgroundRepeat: 'no-repeat', // Optional: Adjust to your needs
        backgroundPosition: 'center', // Optional: Adjust to your needs
      };


      const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };


  return (
    <div className='homeDiv'>
        <div className="fordesktop">
            <Navbar/>
        </div>
        <div  className="homeSlider" style={styles}>
            <div className="formobile">
                <Navbar/>
            </div>
            
        </div>
        <div className="uplifter">
            <div className="uplifterchild">
                <div className="innerlst">
                    HI, HOW CAN WE HELP YOU TODAY?
                </div>
            </div>
        </div>
        <div className="couraselarea">
            <div className="childcaurasel">
                {/* <Carousel cards={cards} /> */} 
                <Carousel responsive={responsive}>
                    <HomeCard img={IMG_9354} name={"Body Wash"} description={"Deep Clean"}/>
                    <HomeCard img={IMG_93744} name={"Body Lotion"} description={"Aloe Vera"}/>
                    <HomeCard img={IMG_9362} name={"Hand Wash"} description={"Deo Fresh"}/>
                    <HomeCard img={IMG_9402} name={"Sanitary Napkins"} description={"Normal Plus"}/>
                    <HomeCard img={IMG_9358} name={"Body Wash"} description={"Rose Care"}/>
                    <HomeCard img={IMG_9370} name={"Body Lotion"} description={"Papaya"}/>
                    <HomeCard img={IMG_9389} name={"Sanitary Napkins"} description={"Ultra Thin"}/>
                    <HomeCard img={IMG_9387} name={"Sanitary Napkins"} description={"Ultra Thin"}/>
                    <HomeCard img={IMG_9410} name={"Sanitary Napkins"} description={"Maxi Night Fresh"}/>
                </Carousel>

            </div>
        </div>
        <div className="homeskinheading">
            SKIN CARE WITH PURITY LOTION
        </div>
        <PictureCard/>
        <div className="bluediv">
            <div className="bluedivchild">
                <div className="heading">
                    GET INSPIRED WITH PURITY PRODUCTS
                </div>
                <div className="circlecards">
                    <div className="singlescard">
                        <div className="circle">
                            <img src={lotion} alt="" />
                            <div className="imagetitle">
                                Lotion
                            </div>

                        </div>
                        <div className="text">
                        Get the best lotion from us with great value. 
                        </div>
                    </div>
                    <div className="singlescard">
                        <div className="circle">
                            <img src={wash} alt="" />
                            <div className="imagetitle">
                                Body Wash
                            </div>

                        </div>
                        <div className="text">
                        Discover the best body wash soap with us.
                        </div>
                    </div>
                    <div className="singlescard">
                        <div className="circle">
                            <img src={dish} alt="" />
                            <div className="imagetitle">
                                Dish Wash
                            </div>

                        </div>
                        <div className="text">
                        Experience the best dish wash with us. 
                        </div>
                    </div>
                    <div className="singlescard">
                        <div className="circle">
                            <img src={hand} alt="" />
                            <div className="imagetitle">
                               Hand Soap
                            </div>

                        </div>
                        <div className="text">
                        Experience the best Hand wash soap with us. 
                        </div>
                    </div>
                    <div className="singlescard">
                        <div className="circle">
                            <img src={napkin} alt="" />
                            <div className="imagetitle">
                                Napkins
                            </div>

                        </div>
                        <div className="text">
                        Get the best napkins from us. 
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <Footer/>
        <Lower/>
    </div>
  )
}

export default Home
